import { useState, useCallback, useMemo, useEffect } from 'react';
import { useForm } from 'src/components/Form';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import { useStoreUnitActions } from 'src/redux/helpers';
import { Box, Button, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getAlertRuleFormFields } from '../formFields';
import { SolutionAlertRuleItem } from '../../types';
import solutionsStore, { useCurrentSolutionName } from '../../redux';

type Props = {
  item?: SolutionAlertRuleItem;
  title: string;
  loading?: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
  onSubmit: (values: SolutionAlertRuleItem) => void;
  confirmBtnText?: string;
  repositoryType?: string;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  height: '500px',
  overflowY: 'auto',
  padding: '8px',
  alignItems: 'center',
};

function SolutionAlertRuleEditModal({
  item,
  title,
  loading,
  open,
  onClose,
  onSubmit,
  confirmBtnText = 'Create',
}: Props) {
  const solutionName = useCurrentSolutionName();
  const { solutionId } = useParams();
  const actions = useStoreUnitActions(solutionsStore);
  const [aggType, setAggType] = useState(item?.aggType);

  const handleKeyDown = (e) => {
    if (e.key === '-' || e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  const onChange = useCallback(
    ({ aggType }: SolutionAlertRuleItem) => {
      setAggType(aggType);
    },
    [aggType]
  );

  const fields = useMemo(
    () => getAlertRuleFormFields(!!item, aggType, handleKeyDown),
    [!!item, aggType]
  );

  const [form, handleSubmit] = useForm({
    visibleValuesOnly: true,
    values: item,
    fields,
    sx: containerStyle,
    onSubmit,
    onChange,
  });

  return (
    <ModalDialog
      open={open}
      title={title}
      confirmBtnText={confirmBtnText}
      loading={loading}
      onClose={onClose}
      maxWidth="lg"
      titleStyle={{ height: '67px' }}
      actionsStyle={{ height: '87px' }}
      showCancelBtn={false}
      showConfirmBtn={false}
      contentStyle={{ paddingRight: '0px' }}
      fullWidth
    >
      {form}
      <Box textAlign="right" mt={3} style={{ paddingRight: '20px' }}>
        <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          {!item ? 'Create' : 'Save'}
        </Button>
      </Box>
    </ModalDialog>
  );
}

export default SolutionAlertRuleEditModal;
