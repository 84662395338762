import * as Yup from 'yup';
import FileUploadInput from 'src/components/FileUploadInput';
import { SxProps } from 'src/theme/types/sxProps';
import ReadonlyField from 'src/components/ReadonlyField';
import { TextField } from '@mui/material';
import Select from 'src/components/Select';
import { ALLOWED_EXTENSION } from '../SolutionDeviceConfig/constants';
import { SolutionAssetTwinField } from '../types';

const inputStyle: SxProps = { width: ({ spacing }) => spacing(42) };

const fileInputField = {
  Component: FileUploadInput,
  type: 'file',
  required: true,
  validation: Yup.mixed()
    .required('A file is required')
    .test(
      'fileFormat',
      ({ value }) =>
        `This file (${value?.name}) type is not supported. Only ${ALLOWED_EXTENSION} are allowed.`,
      (value) => value?.type === ALLOWED_EXTENSION
    ),
};

export const uploadFormFields = [
  {
    name: 'SerializedJsonModel',
    label: 'Upload JSON Files',
    ...fileInputField,
  },
];

const uploadTwinData = {
  Component: FileUploadInput,
  type: 'file',
};

export const addTwinFormFields = (
  getOptions: { value: string; label: string }[] | undefined,
  level: number,
  defaultTemplate: any
) => [
  {
    name: SolutionAssetTwinField.twinId,
    label: 'Name',
    Component: TextField,
    placeholder: 'Enter name',
    required: true,
    validation: Yup.string().required('Name is required'),
    sx: inputStyle,
  },
  level > 3
    ? {
        name: SolutionAssetTwinField.modelId,
        label: 'Model',
        Component: Select,
        placeholder: 'Select the option',
        required: true,
        validation: Yup.string().required('Severity is required'),
        options: getOptions,
        sx: inputStyle,
      }
    : {
        name: SolutionAssetTwinField.modelId,
        label: 'Model',
        Component: ReadonlyField,
        displayValue: true,
        labelStyle: { fontWeight: 'bold', flex: 1, paddingLeft: '4px' },
        value: defaultTemplate[level],
        sx: { width: ({ spacing }) => spacing(34) },
      },
  {
    name: 'TwinData',
    label: 'Upload Twin Data',
    ...uploadTwinData,
  },
];
