import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SolutionAssetBulkDigitalTwinsItem } from 'src/pages/Solutions/types';
import {
  Collapse,
  List,
  ListItem,
  ListItemBaseProps,
  ListItemProps,
  Typography,
} from '@mui/material';

const CustomTreeItemLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  fontSize: '1.6rem',
  padding: theme.spacing(0.5, 1),
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  borderRadius: theme.shape.borderRadius,
}));

const StyledTreeItem = styled((props: any) => <ListItem {...props} />)(
  ({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    '&.Mui-focused, .Mui-selected': {
      backgroundColor: theme.palette.action.selected,
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  })
);

function RenderTreeItems(
  nodes: SolutionAssetBulkDigitalTwinsItem[],
  level = 0,
  selectedIndex,
  onItemClick: ((node: SolutionAssetBulkDigitalTwinsItem) => void) | undefined,
  openNodes: string[],
  toggleNode: (nodeId: string) => void
) {
  return nodes.map((node) => {
    const hasChildren = node.children && node.children.length > 0;
    const isOpen = openNodes.includes(node.id);
    const isSelected = selectedIndex === node.id;

    let icon: any = null;
    if (hasChildren) {
      icon = isOpen ? <ExpandMoreIcon /> : <ChevronRightIcon />;
    }
    return (
      <div key={node.id}>
        <StyledTreeItem
          button
          selected={isSelected}
          key={node.id}
          nodeId={node.id}
          onClick={() => {
            if (node.children) {
              toggleNode(node.id);
            }
            if (onItemClick) {
              onItemClick(node);
            }
          }}
          sx={{
            pl: icon ? level * 2 : level * 2.4,
          }}
        >
          <CustomTreeItemLabel>
            {icon}
            {node.label || node.id}
          </CustomTreeItemLabel>
        </StyledTreeItem>
        {hasChildren && (
          <Collapse
            in={openNodes.includes(node.id)}
            timeout="auto"
            unmountOnExit
          >
            {RenderTreeItems(
              node.children,
              level + 1,
              selectedIndex,
              onItemClick,
              openNodes,
              toggleNode
            )}
          </Collapse>
        )}
      </div>
    );
  });
}

interface SelectTreeViewProps {
  items: SolutionAssetBulkDigitalTwinsItem[] | any | undefined;
  onItemClick?: (node: SolutionAssetBulkDigitalTwinsItem) => void;
}

const SelectTreeView: React.FC<SelectTreeViewProps> = ({
  items,
  onItemClick,
}) => {
  const [openNodes, setOpenNodes] = useState<string[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(0);

  const toggleNode = (nodeId) => {
    setOpenNodes((prev: any) =>
      prev.includes(nodeId)
        ? prev.filter((id) => id !== nodeId)
        : [...prev, nodeId]
    );
  };

  const handleItemClick = (node: SolutionAssetBulkDigitalTwinsItem) => {
    setSelectedIndex(node.id);
    if (onItemClick) {
      onItemClick(node);
    }
  };

  return (
    <Box
      sx={{
        minHeight: 200,
        width: 'auto',
        maxWidth: '100%',
        borderRadius: 1,
        overflow: 'auto',
        padding: 0,
        margin: 0,
      }}
    >
      {items ? (
        <List
          sx={{
            padding: 0,
            margin: 0,
            '& .MuiList-root': {
              margin: '4px 0',
            },
          }}
        >
          {RenderTreeItems(
            items,
            0,
            selectedIndex,
            handleItemClick,
            openNodes,
            toggleNode
          )}
        </List>
      ) : (
        <Typography variant="body2" color="#A5A5A5" align="center">
          No options available
        </Typography>
      )}
    </Box>
  );
};

export default SelectTreeView;
