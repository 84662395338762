import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { generateApiPath } from 'src/utils';
import ModalDialog, {
  isModalComplete,
  ModalCloseHandler,
  ModalCloseReason,
} from 'src/components/ModalDialog';
import { useForm, FormField } from 'src/components/Form';
import {
  pepApiRequest,
  ContentType,
  RequestMethod,
  useStatefulApi,
} from 'src/services';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { useStoreUnitActions } from 'src/redux/helpers';
import { PageCardLoader } from 'src/components/PageCardLayout';
import solutionsStore, {
  useSolutionAssetEdgeDetails,
  useSolutionDetails,
} from '../../redux';
import { addTwinFormFields, uploadFormFields } from '../formFields';
import {
  API_ASSET_CREATE_TWIN,
  API_ASSET_RELATIONSHIP,
  API_ASSET_UPDATE_PROPERTY,
  API_POST_ASSET_CONFIG_UPLOAD,
} from '../../constants';

type Props = {
  api: string;
  formFields: FormField[];
  title: string;
  open: boolean;
  onClose: ModalCloseHandler;
  sitesId?: string;
  id?: string;
  level: number;
  siteIds: any;
  setLoader: any;
};

const defaultTemplate = {
  0: 'dtmi:digitaltwins:isa95:Site;1',
  1: 'dtmi:digitaltwins:isa95:Area;1',
  2: 'dtmi:digitaltwins:isa95:WorkCenter;1',
  3: 'dtmi:digitaltwins:isa95:WorkUnit;1',
};

function SolutionAddTwinModal({
  id,
  siteIds,
  level,
  open,
  setLoader,
  onClose,
}: Props) {
  const [data] = useSolutionDetails();
  const actions = useStoreUnitActions(solutionsStore);
  const { showAlert } = useAlert();
  const { solutionId } = useParams();

  const getOptions = () =>
    data?.industriallDigitalTwinsModelIds?.map((value) => ({
      value,
      label: value,
    }));

  const [onSubmit, loading] = useStatefulApi(
    (params) =>
      pepApiRequest({
        method: RequestMethod.Post,
        url: generateApiPath(API_ASSET_CREATE_TWIN, {
          solutionId,
          siteId: siteIds,
          modelId: level > 3 ? params.modelId : defaultTemplate[level],
          twinId: params.twinId,
        }),
        contentType: ContentType.FormData,
        params: { TwinData: params.TwinData },
      }).then((response) => {
        setLoader(true);
        pepApiRequest({
          method: RequestMethod.Post,
          url: generateApiPath(API_ASSET_RELATIONSHIP, {
            solutionId,
            twinId: id,
          }),
          params: {
            relationshipName: 'contains',
            targetId: params.twinId,
            properties: {},
          },
        })
          .then((response) => {
            setLoader(true);
            const timeOutId = setTimeout(() => {
              actions.assetBulkDigitalTwins({
                solutionId,
                siteIds,
                twinId: 'PepsiCo',
              });
              setLoader(false);
            }, 5000);
            return () => {
              if (timeOutId) {
                setLoader(false);
                clearTimeout(timeOutId);
              }
            };
          })
          .catch((error) => {
            setLoader(false);
          });
      }),
    (res, params) => {
      showAlert({
        type: AlertType.Success,
        title: 'Twin created',
        text: `${params.twinId} twin created successfully`,
      });
      onClose(null, ModalCloseReason.completeAction);
    }
  );

  const [formComponent, handleSubmit] = useForm({
    fields: addTwinFormFields(getOptions(), level, defaultTemplate),
    onSubmit,
  });

  return (
    <>
      <ModalDialog
        title="Add Twin"
        open={open}
        loading={loading}
        onClose={onClose}
        onConfirm={handleSubmit}
        confirmBtnText="Save"
        maxWidth="sm"
        fullWidth
      >
        {formComponent}
      </ModalDialog>
    </>
  );
}

export default SolutionAddTwinModal;
