export type SolutionAssetBulkDigitalTwinsData = {
  data: SolutionAssetBulkDigitalTwinsItem[];
  loading?: boolean;
};

export type SolutionAssetBulkDigitalTwinsItem = {
  id: string;
  relationshipName: string;
  siteId?: string;
  label?: string;
  level: number;
  properties: Record<string, any>;
  icon?: React.ReactNode;
  children: SolutionAssetBulkDigitalTwinsItem[];
};

export type SolutionAssetBulkDigitalTwinsRootItem = {
  root: SolutionAssetBulkDigitalTwinsItem;
};

export type SolutionAssetEdgeDetailsItem = {
  uId: string;
  twinId?: string;
  relationshipName?: string;
  properties: {
    tagName?: string;
    unit?: string;
    plantId?: number;
  };
  enterprise: string;
  site?: string;
  area?: string;
  workCenter?: string;
  workUnit?: string;
};

export type SolutionAssetEdgeDetailItem = {
  data: SolutionAssetEdgeDetailsItem;
};

export type SolutionAssetTwin = {
  twinId?: string;
  modelId?: string;
};

export enum SolutionAssetTwinField {
  twinId = 'twinId',
  modelId = 'modelId',
}
